<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <!-- <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">&nbsp;</span>
      </h3> -->
      <div class="card-toolbar d-flex justify-content-between justify-content-sm-end w-100">
        <div class="d-flex align-items-center mr-sm-12 my-2">
          <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
            <input type="checkbox" name="" v-model="show_history" @click="show_history = !show_history" />
            <span></span>
          </label>
          <span class="ml-3 cursor-pointer">{{$t('PAGES.EVENTS.PREVIOUS_EVENTS')}}</span>
        </div>

        <a href="#" class="btn btn-primary font-weight-bolder font-size" @click="create_event_clicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1.0em;"></i>{{$t('PAGES.EVENTS.NEW_EVENT')}}</a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3 table-reponsive">


      <b-table
        id="event-table"
        :fields="headers"
        :items="items"
        head-variant="light"
        sticky-header
        class="mh-100 table-striped"
      >
        <template #cell(status)="row">

          <v-chip
            style="font-size: 10px; color: red; border-color: red;"
            v-if="row.item.status === 'UNKNOWN'"
            color="red"
            outlined
          >
            {{ $t('PAGES.EVENTS.STATUSES.UNKNOWN') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: red; border-color: red;"
            v-if="row.item.status === 'ARCHIVED'"
            color="red"
            outlined
          >
            {{ $t('PAGES.EVENTS.STATUSES.ARCHIVED') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: orange; border-color: orange;"
            v-if="row.item.status === 'DRAFT'"
            color="orange"

            outlined
          >
            {{ $t('PAGES.EVENTS.STATUSES.DRAFT') }}
          </v-chip>

          <v-chip
            style="font-size: 10px; color: green; border-color: green;"
            v-if="row.item.status === 'ACTIVE'"
            color="green"
            outlined
          >
            {{ $t('PAGES.EVENTS.STATUSES.ACTIVE') }}
          </v-chip>
          
        </template>


        <template #cell(event_id)="row">
          <div class='justify-content-end d-flex'>

            <memlist-table-button class="mr-3" @click="goto_event_url(row.item.event_id)" svg="/assets/svg/Directions.svg" :tooltip="$t('PAGES.EVENTS.TOOLTIP_URL')" />
            
            <memlist-table-button class="mr-3" @click="select_event_clicked(row.item.event_id)" svg="/assets/svg/Write.svg" :tooltip="$t('PAGES.EVENTS.TOOLTIP_EDIT')" />
            
            
            <memlist-table-button class="mr-3" @click="copy_event_clicked(row.item.event_id)" icon="far fa-copy" :tooltip="$t('PAGES.EVENTS.TOOLTIP_COPY')" />
            
            <memlist-table-button v-if="row.item.status === 'ARCHIVED'" class="mr-3" @click="delete_event_clicked(row.item.event_id)" svg="/assets/svg/Trash.svg" :tooltip="$t('PAGES.EVENTS.TOOLTIP_DELETE')" />
            <memlist-table-button v-if="row.item.status === 'DRAFT' || row.item.status === 'ACTIVE'" class="mr-3" @click="archive_event_clicked(row.item)" icon="fa fa-archive" :tooltip="$t('PAGES.EVENTS.TOOLTIP_ARCHIVE')" />
            
            
          </div>
        </template>
      </b-table>

    </div>
    <!--end::Body-->
  </div>
  <!--end::Advance Table Widget 9-->
</template>

<style lang="scss" scoped>



.status-badge {
  width: 100px;
  display: flex;
  align-items: center;
  font-size: 0.85em;
  padding: 4px 8px;
  border-radius: 1pc;
  border: 2px solid;
  text-align: center;
  font-weight: bold;
  overflow: hidden;
}

.status-badge.active {
  background-color: #ffffff;
  color: #33ff33;
  border-color: #33ff33;
}

.status-badge.archive {
  background-color: #ffffff;
  color: #3333de;
  border-color: #3333de;
}

.status-badge.draft {
  background-color: #ffffff;
  color: #444444;
  border-color: #444444;
}

.b-table-sticky-header{
  overflow-y: hidden;
  max-height: unset;
}

</style>
<script>
import axios from 'axios';
import dayjs from 'dayjs';
import EventsTableRow from '@/view/pages/ml/events/EventsTableRow.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'events-table',
  mixins: [ toasts ],
  props: ['items', 'currentCompanyId'],
  emits: ['show_history_toggled', 'create_event_clicked', 'select_event_clicked', 'delete_event_clicked', 'publish_event_clicked'],
  components: {
    EventsTableRow
  },
  watch: {
    show_history(newcheck, oldcheck) {
      this.$emit('show_history_toggled', newcheck);
    }
  },
  methods: {
    create_event_clicked() {
      this.$emit('create_event_clicked');
    },
    select_event_clicked(event_id) {
      this.$emit('select_event_clicked', event_id);
    },
    archive_event_clicked(event) {
      this.$emit('archive_event_clicked', event);
    },
    delete_event_clicked(event_id) {
      this.$emit('delete_event_clicked', event_id);
    },
    copy_event_clicked(event_id) {
      this.$emit('copy_event_clicked', event_id);
    },
    publish_event_clicked(event_id){
      this.$emit('publish_event_clicked', event_id)
    },
    gotoEvent(event_id){
      this.$router.push(`/event/${event_id}`)
    },
    goto_event_url(event_id) {
      window.open('/event/'+event_id, '_blank');
    }
  },
  data() {
    return {
      textFilter: null,
      show_history: false,
      headers: [
        {
          key: 'name',
          label: this.$t('PAGES.EVENTS.EVENTS'),
          sortable: true,
          thClass: 'pl-7 align-middle',
          tdClass: 'pl-7'
        },
        {
          key: 'status',
          label: this.$t('PAGES.EVENTS.STATUS'),
          sortable: true,
          thClass: 'align-middle',
          tdClass: '',
        },
        {
          key: 'from_datetime',
          label: this.$t('PAGES.EVENTS.START_TIME'),
          sortable: true,
          thClass: 'w-140px align-middle',
          tdClass: 'w-140px',
          formatter: (_, __, item) => {
            return dayjs(item.from_datetime).isValid() ? dayjs(item.from_datetime).format('YYYY-MM-DD') : '-';
          },
          
        },
        {
          key: 'signups',
          label: this.$t('PAGES.EVENTS.NUM_SIGNUPS'),
          formatter: (_, __, item) => {
            return item.num_tickets ? item.num_tickets : 0;
          },
          sortByFormatted: true,
          sortable: true,
          thClass: 'w-100px align-middle',
          tdClass: 'w-100px',
        },
        {
          key: 'num_seats',
          label: this.$t('PAGES.EVENTS.NUM_SEATS'),
          sortable: true,
          thClass: 'w-150px align-middle',
          tdClass: 'w-150px',
        },
        {
          key: 'event_id',
          label: this.$t('COMMON.ACTIONS'),
          thClass: 'text-right pr-22 w-110px align-middle',
        },
      ],
      list: []
    };
  }
};
</script>
